<template>
  <div class="home">
    <!-- 轮播 -->
    <div class="swiper_wrap">
      <swiper class="top_swiper" :options="swiperOption">
        <swiper-slide class="slide" v-for="(item, index) in carouselList" :key="index">
          <img class="slide_img" :src="item.img" />
          <h1 class="h1 text-white slide_title">{{ item.title }}</h1>
          <h3 class="h1 text-white slide_subtitle">{{ item.subtitle }}</h3>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </div>
    <!-- 公司介绍 -->
    <div class="intro_section">
      <div class="container-fluid">
        <div class="row">
          <div class="col col-sm-12 col-lg-6">
            <div class="intro_img">
              <b-img fluid :src="info.infoCover" />
            </div>
          </div>
          <div class="col col-sm-12 col-lg-6">
            <div class="intro_wrap">
              <p class="intro_top"><span>{{ info.infoLogo }}</span></p>
              <p class="intro_title">{{ info.infoTitle }}</p>
              <p class="intro_text" v-html="info.infoDesc"></p>
              <div class="d-flex mt-4">
                <div class="d-flex flex-column flex-fill num_item">
                  <div class="num_item_top">
                    <count-to :start-val="0" :end-val="parseInt(info.infoYear)" :duration="3600" separator=""
                      class="intro_num" />
                    <span class="num_unit">年</span>
                  </div>
                  <div class="num_subname">公司成立</div>
                </div>
                <div class="d-flex flex-column flex-fill num_item">
                  <div class="num_item_top">
                    <count-to :start-val="0" :end-val="parseInt(info.infoCity)" :duration="3600" separator=""
                      class="intro_num" />
                    <span class="num_unit">+</span>
                  </div>
                  <div class="num_subname">{{ info.infoCityText }}</div>
                </div>
                <div class="d-flex flex-column flex-fill num_item">
                  <div class="num_item_top">
                    <count-to :start-val="0" :end-val="parseInt(info.infoPatent)" :duration="3600" separator=""
                      class="intro_num" />
                    <span class="num_unit">+</span>
                  </div>
                  <div class="num_subname">{{ info.infoPatentText }}</div>
                </div>
              </div>
              <b-button class="diy-outline-btn more_btn" to="/about">查看更多 +</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="product_section">
      <div class="d-flex flex-column product_inner">
        <div class="product_title_wrap">
          <p class="product_subtitle"><span>{{ info.productText }}</span></p>
          <p class="product_title">{{ info.productTitle }}</p>
        </div>
        <div class="product_list_wrap">
          <swiper class="product_swiper" :options="productSwiperOption">
            <swiper-slide v-for="(item, index) in productList" :key="index">
              <div class="d-flex flex-column align-items-center rounded shadow-sm product_item"
                @click="toProduct(item.id)">
                <p class="product_name">{{ item.name }}</p>
                <div class="product_img_wrap">
                  <b-img fluid class="product_img" :src="item.cover"></b-img>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-scrollbar" slot="scrollbar"></div>
          </swiper>
          <!-- 加载。。。 -->
          <div class="d-flex justify-content-center mb-5" v-if="productList.length <= 0">
            <b-spinner label="Loading..." variant="gray"></b-spinner>
          </div>
        </div>
      </div>
    </div>
    <!-- 资讯动态 -->
    <div class="news_section">
      <div class="d-flex flex-column news_inner">
        <div class="news_title_wrap">
          <p class="news_subtitle"><span>{{ info.newsText }}</span></p>
          <div class="d-flex w-100">
            <p class="news_title">{{ info.newsTitle }}</p>
            <b-button pill class="diy-outline-btn more_btn align-self-center" @click="toNews()">查看更多</b-button>
          </div>
        </div>
        <div class="news_list_wrap">
          <swiper class="news_swiper" :options="newsSwiperOption">
            <swiper-slide v-for="(item, index) in newList" :key="index">
              <div class="card shadow-sm news_item" @click="toNews(item.id)">
                <div class="card_img overflow-hidden">
                  <img class="card-img-top" :src="item.cover" />
                </div>
                <div class="card-body text-left">
                  <h6 class="card-subtitle mb-2">
                    {{ parseTime(item.createTime, '{y}-{m}-{d}') }}
                  </h6>
                  <h5 class="card-title font-weight-bold text-truncate">{{ item.title }}</h5>
                  <p class="card-text text-truncate">{{ item.subtitle }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- 加载。。。 -->
          <div class="d-flex justify-content-center mb-5" v-if="newList.length <= 0">
            <b-spinner label="Loading..." variant="primary"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCarousel, topProduct, topNews } from '@/api/website'
import CountTo from 'vue-count-to'

export default {
  name: 'HomeView',
  components: { CountTo },
  computed: {
    ...mapGetters(['firm', 'info'])
  },
  data: () => ({
    swiperOption: {
      spaceBetween: 30,
      effect: 'fade',
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    productSwiperOption: {
      slidesPerView: 4,
      spaceBetween: 30,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      }
    },
    newsSwiperOption: {
      slidesPerView: 3,
      spaceBetween: 50,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 50
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      }
    },
    carouselList: [],
    newList: [],
    productList: []
  }),
  created() {
    this.getCarouselList()
    this.getNewsList()
    this.getProductList()
  },
  methods: {
    getCarouselList() {
      getCarousel().then(res => {
        this.carouselList = res.data
      })
    },
    getNewsList() {
      topNews().then(res => {
        this.newList = res.data
      })
    },
    getProductList() {
      topProduct().then(res => {
        this.productList = res.data
      })
    },
    toProduct(id) {
      if (id) {
        this.$router.push('/product/' + id)
      } else {
        this.$router.push('/product')
      }
    },
    toNews(id) {
      if (id) {
        this.$router.push('/news/' + id)
      } else {
        this.$router.push('/news')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top_swiper {
  width: 100%;
  height: calc(100vh - 280px);
  background-color: #fff;

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    background-position: center;
    background-size: cover;
    background-color: #fff;

    .slide_img {
      position: absolute;
      width: auto;
      margin: 0 auto;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      // position: absolute;
      // top: 50%;
      // left: 50%;
      // -webkit-transform: translate(-50%, -50%);
      // transform: translate(-50%, -50%);
      // max-width: 100%;
      // height: auto;
    }

    // &.slide-1 {
    //   background-image: url("https://omo-oss-image.thefastimg.com/portal-saas/new2023062610352150175/cms/image/8455ede8-f18d-4867-83a8-9a2655f4ed3b.jpg");
    // }
    // &.slide-2 {
    //   background-image: url("https://v1.github.surmon.me/images/example/6.jpg");
    // }
    // &.slide-3 {
    //   background-image: url("https://v1.github.surmon.me/images/example/7.jpg");
    // }
    // &.slide-4 {
    //   background-image: url("https://v1.github.surmon.me/images/example/8.jpg");
    // }
    // &.slide-5 {
    //   background-image: url("https://v1.github.surmon.me/images/example/9.jpg");
    // }
  }
}

::v-deep .swiper-pagination-bullet-active {
  background: var(--swiper-color);
}

::v-deep .swiper-button-prev,
.swiper-button-next {
  color: var(--swiper-color);
}

.slide_title {
  font-size: 60px;
  // font-weight: bold;
  z-index: 1;
}

.slide_subtitle {
  font-size: 20px;
  // font-weight: bold;
  z-index: 1;
  padding: 20px 15%;
}

.intro_section {
  padding: 120px 40px;

  .intro_img {
    padding: 10px 3%;

    img {
      border-radius: 20px;
    }
  }
}

.intro_wrap {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px 3%;
  padding-right: 6%;

  .intro_top {
    position: relative;
    color: var(--title-color);
    font-size: 24px;
    margin-bottom: 12px;

    & span {
      position: relative;
    }

    & span::before {
      content: '';
      position: absolute;
      top: 13px;
      left: 105%;
      width: 80px;
      height: 1px;
      background: var(--title-color);
    }
  }

  .intro_title {
    color: var(--title-color);
    font-size: 52px;
    margin-bottom: 15px;
  }

  .intro_text {
    color: var(--text-color);
    font-size: 16px;
    margin-bottom: 0px;
  }

  .num_item {
    text-align: center;
    justify-content: center;
    align-items: center;

    .num_item_top {
      height: 60px;
      line-height: 60px;
      font-size: 46px;
      text-align: center;
      font-weight: bold;
    }

    .intro_num {
      font-size: 54px;
      color: var(--title-color);
      font-family: Arial;
    }

    .num_unit {
      font-size: 14px;
      color: #999;
      position: relative;
      top: 0px;
      font-weight: normal;
    }

    .num_subname {
      text-align: center;
      font-size: 15px;
      color: rgba(119, 119, 119, 1);
      margin-top: 5px;
    }
  }

  .more_btn {
    padding: 10px 40px;
    font-size: 16px;
    margin-top: 40px;
  }
}

.product_section {
  background-color: #fafafa;

  .product_inner {
    padding: 60px 80px 80px;
  }

  .product_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .product_subtitle {
      position: relative;
      font-size: 24px;
      color: var(--title-color);
      margin-bottom: 10px;
      text-align: right;

      & span {
        position: relative;
      }

      & span::before {
        content: '';
        position: absolute;
        top: 13px;
        right: 105%;
        width: 80px;
        height: 1px;
        background: var(--title-color);
      }
    }

    .product_title {
      font-size: 52px;
      color: var(--title-color);
      text-align: right;
    }
  }

  .product_swiper {
    padding-top: 20px;
    padding-bottom: 80px;

    .swiper-scrollbar {
      width: 50%;
      left: auto;
      right: 0;

      ::v-deep .swiper-scrollbar-drag {
        background-color: #fff;
      }
    }
  }

  .product_item {
    padding: 40px;
    background-color: #fff;

    .product_name {
      font-size: 20px;
      font-weight: bold;
      color: var(--item-color);
      transition: all 0.3s ease;
    }

    &:hover .product_name {
      color: var(--item-color);
    }

    .product_img_wrap {
      position: relative;
      height: 300px;
      // padding-bottom: 100%;
      // overflow: hidden;
    }

    .product_img {
      transition: all 0.3s;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover .product_img {
      transform: scale(1.1, 1.1);
    }
  }
}

.news_section {
  background-color: #fff;

  .news_inner {
    padding: 60px 80px;
  }

  .news_title_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    .news_subtitle {
      position: relative;
      font-size: 24px;
      color: var(--title-color);
      margin-bottom: 10px;

      & span {
        position: relative;
      }

      & span::before {
        content: '';
        position: absolute;
        top: 13px;
        left: 105%;
        width: 80px;
        height: 1px;
        background: var(--title-color);
      }
    }

    .news_title {
      font-size: 48px;
      color: var(--title-color);
      margin-right: auto;
    }

    .more_btn {
      width: 160px;
      padding: 8px;
    }
  }

  .news_item {
    .card_img {
      height: 350px;

      img {
        transition: all 0.3s;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &:hover .card_img>img {
      transform: scale(1.1, 1.1);
    }
  }

  // .card_img {
  //   height: 400px;
  //   overflow: hidden;
  // }
}
</style>
