<template>
  <div>
    <nav-head />
    <router-view />
    <page-footer />
  </div>
</template>

<script>
import { NavHead, PageFooter } from "./components";
import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: "Layout",
  components: { NavHead, PageFooter },
  mixins: [ResizeMixin],
};
</script>
