
import CryptoJS from 'crypto-js'

// 企业标识
const FIRM_KEY = 'abb987415911179b' || process.env.FIRM_KEY


/**
 * 签名数据
 */
export const signData = (params, timestamp, secret) => {
	params = { ...params, timestamp, secret }
	const sortedKeys = Object.keys(params).sort()
	const values = []
	sortedKeys.forEach(key => {
		let paramsStr = params[key]
		if (params[key] instanceof Object) {
			paramsStr = JSON.stringify(params[key])
		}
		params[key] !== null && params[key] !== undefined && values.push(`${key}=${paramsStr}`)
	})
	const signStr = values.join('&')
	// console.log(signStr)
	return CryptoJS.MD5(signStr).toString().toLowerCase()
}


/**
 * 签名参数
 */
export const signedParams = (params, secret) => {
	// 时间戳
	let timestamp = new Date().getTime()
	// 签名参数
	return signedParams = Object.assign({ timestamp }, params, { sign: signData(params, timestamp, secret) })
}


/**
 * 签名Token
 */
export const tokenHeader = (params) => {
	// 时间戳
	let timestamp = new Date().getTime()
	// 签名
	let sign = signData(params, timestamp, FIRM_KEY)
	// 返回
	return `${sign}-${FIRM_KEY}-${timestamp}`
}