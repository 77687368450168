import router from './router'
import store from './store'
import { MessageBox  } from 'element-ui'

let isTrue = true

router.beforeEach((to, from, next) => {
  // 判断当前是否已拉取完企业信息
  if (!store.getters.info?.id && isTrue) {
    isTrue = false
    store.dispatch('GetInfo').then(({data}) => {
      isTrue = true
      const endTime = new Date(data.endDate).getTime()
      const nowTime = new Date().getTime()
      // 如果当前时间大于到期时间
      if (endTime < nowTime || data.status != '0') {
        console.log('请联系管理员即时续费')
        MessageBox.alert('<strong style="color: #f56c6c; font-size: 18px">请联系管理员即时续费</strong>', '到期提示', { type: 'warning', dangerouslyUseHTMLString: true })
      }
    })
  }
  // 判断是否拉取商品分类
  if (store.getters.classify.length == 0) {
    store.dispatch('GetClassify').then(() => {})
  }
  // 判断是否拉取企业菜单
  if (store.getters.menu.length == 0) {
    store.dispatch('GetMenu').then(accessRoutes => {
      router.addRoutes(accessRoutes) // 动态添加可访问路由表
      next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
    })
  }else {
    next()
  }
  
})

router.afterEach(() => {window.scrollTo(0,0);})
