import axios from 'axios'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from "@/utils/tool";
import {tokenHeader} from '@/utils/sign';
import { saveAs } from 'file-saver'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://data.longtect.cc/core-api/website/api/',
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 请求参数
  let paramsData = {}
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    paramsData = config.params
    // 转换参数
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  } else if(config.method === 'post' || config.method === 'put') {
    paramsData = config.data
  }
  // 是否签名
  if (!isToken) {
    config.headers['token'] = tokenHeader(paramsData)
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
      return res.data
    }
    if (code === 500) {
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      return Promise.reject('error')
    } else if (code !== 200) {
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    return Promise.reject(error)
  }
)

// 通用下载方法
export async function download(url, params, filename, config) {
  try {
    const data = await service.post(url, params, {
      transformRequest: [(params_1) => { return tansParams(params_1); } ],
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
      ...config
    });
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data]);
      saveAs(blob, filename);
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default'];
    }
  } catch (r) {
    console.error(r);
  }
}

export default service
