import Vue from "vue";
import Vuex from "vuex";
import website from './modules/website';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { website },
  getters: {
    info: state => state.website.info,
    firm: state => state.website.firm,
    classify: state => state.website.classify,
    classifyList: state => state.website.classifyList,
    menu: state => state.website.menu,
    menuList: state => state.website.menuList,
    menuOptions: state => state.website.menuOptions,
  }
});
