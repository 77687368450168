import request from '@/utils/request'

// 查询企业网站设置
export function getFirmSet() {
  return request({
    url: '/firm',
    method: 'get'
  })
}

// 查询菜单
export function getMenu() {
  return request({
    url: '/menu',
    method: 'get'
  })
}

// 查询路由
export function getRouters() {
  return request({
    url: '/getRouters',
    method: 'get'
  })
}

// 查询轮播图
export function getCarousel() {
  return request({
    url: '/carousel',
    method: 'get'
  })
}

// 查询企业新闻
export function listNews(query) {
  return request({
    url: '/news',
    method: 'get',
    params: {...query}
  })
}

// 查询置顶企业新闻
export function topNews() {
  return request({
    url: '/news/top',
    method: 'get'
  })
}

// 查询企业新闻详情
export function getNews(id) {
  return request({
    url: `/news/${id}`,
    method: 'get'
  })
}

// 查询企业产品
export function listProduct(query) {
  return request({
    url: '/product',
    method: 'get',
    params: {...query}
  })
}

// 查询企业产品分类
export function classifyProduct(query) {
  return request({
    url: '/product/classify',
    method: 'get',
    params: {...query}
  })
}

// 查询企业产品详情
export function getProduct(id) {
  return request({
    url: `/product/${id}`,
    method: 'get'
  })
}

// 查询置顶企业产品
export function topProduct() {
  return request({
    url: '/product/top',
    method: 'get'
  })
}

// 查询企业服务支持
export function listService(query) {
  return request({
    url: '/service',
    method: 'get',
    params: {...query}
  })
}

// 查询企业服务支持
export function submitConsult(data) {
  return request({
    url: '/consult',
    method: 'post',
    data: {...data}
  })
}