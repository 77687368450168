<template>
  <div class="nav_head">
    <b-container fluid class="fixed-top bg-white border-bottom">
      <!-- pc端 -->
      <div class="d-flex align-items-center px-4">
        <div>
          <img v-if="info.logo" :src="info.logo" height="70" class="d-inline-block align-top" alt="" />
        </div>
        <div class="d-flex ml-auto pr-5">
          <b-nav fill>
            <!-- 菜单 -->
            <b-nav-item :class="['m_nav_item', { active: current == item.path }]" :data-name="item.name"
              v-b-hover="hoverHandler" v-for="(item, index) in menu" :key="index">
              <b-link class="to_link" @click="toPage(item)">{{
                item.meta?.title
              }}</b-link>
              <!-- 子菜单 -->
              <transition name="fade" v-if="item.children && item.children.length > 0">
                <div class="sub_item_list" v-if="show === item.name">
                  <b-nav vertical class="flex-fill py-2">
                    <b-nav-item v-for="(citem, cindex) in item.children" :key="cindex" @click="toPage(citem)">
                      {{ citem.meta?.title }}
                    </b-nav-item>
                  </b-nav>
                </div>
              </transition>
            </b-nav-item>
          </b-nav>
        </div>
        <div class="d-flex">
          <b-button variant="outline-dark" class="icon-btn mr-2" v-if="false">
            <b-icon icon="search"> </b-icon>
          </b-button>
          <b-button variant="outline-dark" class="icon-btn mr-2" v-b-popover.hover.bottom="`${info?.mailbox}`"
            v-if="info.email == '0'">
            <b-icon icon="envelope"> </b-icon>
          </b-button>
          <b-button variant="outline-dark" class="icon-btn" v-if="info.cnEs == '0'">
            En
          </b-button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavHead',
  data: () => ({ show: '', current: '' }),
  computed: {
    ...mapGetters(['firm', 'info', 'classify', 'menu'])
  },
  watch: {
    $route(route) {
      // console.log(route);
      this.current = route?.path
    }
  },
  created() {
    const route = this.$router?.currentRoute
    // console.log(route, this.menu)
    this.current = route?.path
  },
  methods: {
    hoverHandler(isHovered, event) {
      const { name } = event.target.dataset
      this.show = isHovered ? name : ''
    },
    toPage(menu) {
      this.current = menu.path
      let query = (menu.query && JSON.parse(menu?.query)) || {}
      if (menu.path == '/news') {
        query.menuId = menu.id
      }
      this.$router.push({
        name: menu.name,
        path: menu.path,
        query: { ...query }
      })
      // window.location.href = menu.path;
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.nav_head {
  padding-top: 90px;

  .m_nav_item {
    position: relative;
    line-height: 90px;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 16px;
      background: rgba(0, 0, 0, 0.4);
    }

    .to_link {
      position: relative;
    }

    & .to_link::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -8px;
      width: 0;
      height: 1px;
      margin: 0 auto;
      background-color: var(--nav-line);
      transition: all ease 0.3s;
    }

    &:hover .to_link::after,
    &.active .to_link::after {
      width: 100%;
    }

    // &.active .to_link {
    //   padding-bottom: 5px;
    //   border-bottom: 2px solid var(--Secondary);
    // }

    &.active:hover .to_link {
      // padding-bottom: 5px;
      border-bottom: 0px solid var(--nav-line);
    }

    &:hover>.nav-link,
    &:hover .to_link,
    &.active>.nav-link {
      color: var(--nav-link-active);
      font-weight: 700;
    }

    &>.nav-link {
      font-size: 18px;
      color: var(--nav-link);
      padding: 0px 30px;
    }

    & .to_link {
      color: var(--nav-link);
      text-decoration: none;
    }
  }
}

.sub_item_list {
  display: flex;
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 10;
  transition: all ease 0.3s;
  transform: translateX(-50%);
  background: #fff;
  border-top: var(--nav-link-active) 1px solid;
  // box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  min-width: 100%;

  .nav-item {
    line-height: 1.8;
    font-weight: normal;
  }

  .nav-link {
    font-size: 16px;
    color: #333;
    transition: all ease 0.15s;

    &:hover {
      font-weight: bold;
      color: #fff;
      background: var(--nav-link-active);
    }
  }
}

.sub_item_inner {
  padding: 40px;
  justify-content: center;
  background: #f6f7f9;

  .sub_item_box {
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 16px;

    &:hover p {
      color: var(--nav-link-active);
    }

    p {
      line-height: normal;
      color: #333;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {}
</style>
