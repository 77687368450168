import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout'

const routes = [
  {
    path: '',
    component: Layout,
    redirect: 'home',
    children: [
      // { path: 'home', name: 'home', component: HomeView },
      // {
      //   path: '/product',
      //   name: 'Product',
      //   component: () => import('../views/ProductView.vue')
      // },
      {
        path: '/product/:id',
        name: 'ProductDetail',
        component: () => import('../views/ProductDetailView.vue')
      },
      // {
      //   path: '/about',
      //   name: 'About',
      //   component: () => import('../views/AboutView.vue')
      // },
      // {
      //   path: '/news',
      //   name: 'News',
      //   component: () => import('../views/NewsView.vue')
      // },
      {
        path: '/news/:id',
        name: 'NewsDetail',
        component: () => import('../views/NewsDetailView.vue')
      },
      // {
      //   path: '/service',
      //   name: 'Service',
      //   component: () => import('../views/ServiceView.vue')
      // },
      // {
      //   path: '/contact',
      //   name: 'Contact',
      //   component: () => import('../views/ContactView.vue')
      // }
    ]
  }
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
