import Vue from "vue";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/styles/custom.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'swiper/css/swiper.css'

// permission control
import './permission'

import { parseTime } from '@/utils/tool';

Vue.prototype.parseTime = parseTime

Vue.use(VueAwesomeSwiper)
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
