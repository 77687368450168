import { getFirmSet, classifyProduct, getMenu, getRouters } from '@/api/website'
import {handleTree} from '@/utils/tool';
import Layout from '@/layout'

const website = {
  state: {
    info: {},
    firm: {},
    classify: [],
    classifyList: [],
    menu: [],
    menuList: [],
    menuOptions: []
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_FIRM: (state, firm) => {
      state.firm = firm
    },
    SET_CLASSIFY: (state, classify) => {
      state.classify = classify
    },
    SET_CLASSIFY_LIST: (state, classifyList) => {
      state.classifyList = classifyList
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
    },
    SET_MENU_LIST: (state, menuList) => {
      state.menuList = menuList
    },
    SET_MENU_OPTIONS: (state, menuOptions) => {
      state.menuOptions = menuOptions
    }
  },

  actions: {

    // 获取企业网站信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getFirmSet().then(res => {
          const { settings } = res.data
          // 转换数据
          let form = {};
          settings.forEach((item) => {
            form[item.code] = item.content;
          });
          commit('SET_INFO', form)
          commit('SET_FIRM', res.data)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取企业菜单
    GetMenu({ commit, state }) {
      return new Promise((resolve, reject) => {
        getRouters().then(res => {
          const rdata = JSON.parse(JSON.stringify(res.data))
          const rewriteRoutes = filterAsyncRouter(rdata, false, true)
          commit('SET_MENU', rewriteRoutes)
          resolve([{ path: '', component: Layout, redirect: 'home', meta: {title: '首页'}, children: rewriteRoutes }])
        })
        getMenu().then(res => {
          commit('SET_MENU_LIST', res.data)
          const list = handleTree(res.data)
          commit('SET_MENU_OPTIONS', list)
          // resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取企业产品分类
    GetClassify({ commit, state }) {
      return new Promise((resolve, reject) => {
        classifyProduct().then(res => {
          const list = handleTree(res.data)
          commit('SET_CLASSIFY', list)
          commit('SET_CLASSIFY_LIST', res.data)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'ParentView') {
        route.component = ParentView
      } else if (route.component === 'InnerLink') {
        route.component = InnerLink
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

export const loadView = (view) => {
  if (process.env.NODE_ENV === 'development') {
    return (resolve) => require([`@/views/${view}`], resolve)
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views/${view}`)
  }
}

export default website
