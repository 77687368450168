
const { body } = document
const WIDTH = 992 // refer to Bootstrap's responsive design

export default {
  data() {
    return {device: 'pc'}
  },
  watch: {
    $route(route) {
      if (this.device === 'mobile') {
        window.location.replace('http://www.longtect.cc/mob');
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      window.location.replace('http://www.longtect.cc/mob');
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        this.device = isMobile ? 'mobile':'pc'
        if (isMobile) {
          window.location.replace('http://www.longtect.cc/mob');
        }
      }
    }
  }
}
