<template>
  <div class="footer_wrap">
    <footer class="pt-4 pt-md-4 pb-3 footer_inner">
      <div class="container-fluid footer_box">
        <div class="row no-gutters">
          <div class="col-12 col-md">
            <div class="d-flex flex-column footer_left">
              <!-- <div class="mb-3">
                <b-img
                  src="https://omo-oss-image.thefastimg.com/portal-saas/new2023062610352118181/cms/image/bbe9ecad-6716-4973-af18-dcaa1df4baca.png"></b-img>
              </div> -->
              <p class="left_phone_label">全国服务热线：</p>
              <p class="left_phone">{{ info?.telephone }}</p>
              <p class="left_name">{{ info.name }}</p>
              <div class="left_info">
                <p>地址：{{ info.address }}</p>
                <p>电话：{{ info?.telephone }}</p>
                <p>邮箱：{{ info?.mailbox }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 footer_menu">
            <div class="container-fluid">
              <div class="d-flex justify-content-between">
                <!-- <div class="d-block menu_item">
                  <h5 class="item_name">产品中心</h5>
                  <ul class="list-unstyled text-small">
                    <li v-for="(item, index) in classify" :key="index">
                      <a
                        href="#"
                        @click="toPage('/product', { classifyId: item.id })"
                        >{{ item.name }}</a
                      >
                    </li>
                  </ul>
                </div> -->
                <!-- <div class="d-block menu_item">
                  <h5 class="item_name">关于我们</h5>
                  <ul class="list-unstyled text-small">
                    <li><a href="/about">公司简介</a></li>
                    <li><a href="/about">企业文化</a></li>
                    <li><a href="/about">资质荣誉</a></li>
                  </ul>
                </div>
                <div class="d-block menu_item">
                  <h5 class="item_name">新闻资讯</h5>
                  <ul class="list-unstyled text-small">
                    <li><a href="/news">公司新闻</a></li>
                    <li><a href="/news">行业新闻</a></li>
                  </ul>
                </div>
                <div class="d-block menu_item">
                  <h5 class="item_name">服务支持</h5>
                  <ul class="list-unstyled text-small">
                    <li><a href="/service">售后视频</a></li>
                  </ul>
                </div> -->
                <div class="d-block menu_item" v-for="(item, index) in footMenu" :key="index">
                  <h5 class="item_name" style="cursor: pointer" @click="toPage(item.path)">
                    {{ item.meta?.title }}
                  </h5>
                  <ul class="list-unstyled text-small" v-if="item.path != '/product'">
                    <li v-for="(citem, cindex) in item.children" :key="cindex">
                      <a :href="citem.path">{{ citem.meta?.title }}</a>
                    </li>
                  </ul>
                  <ul class="list-unstyled text-small" v-else>
                    <li v-for="(pitem, pindex) in classify" :key="pindex">
                      <a href="#" @click="toPage(item.path, { classifyId: pitem.id })">{{ pitem.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md">
            <div class="d-flex flex-column">
              <h5 class="qr_name">官方微信</h5>
              <div class="qr_img">
                <b-img :src="info.firmQr"></b-img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="hr_line" />
      <div class="footer_bottom">
        <div>
          <p class="copyright">Copyright © {{ info.name }}</p>
        </div>
        <div class="divider"></div>
        <div class="d-flex align-items-center mr-2" v-if="info.security">
          <b-img class="mr-1" :src="beian"></b-img>
          <p class="security">{{ info.security }}</p>
        </div>
        <div>
          <a class="beian" :href="firm.beianUrl || 'https://beian.miit.gov.cn'" target="_blank">{{
            info.beian
          }}</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import beian from '@/assets/beian.png';

export default {
  name: "PageFooter",
  computed: {
    ...mapGetters(["firm", "info", "classify", "menu"]),
    footMenu() {
      let footMenu = this.menu.filter((item) => !item.hidden);
      return footMenu;
    },
  },
  data: () => ({ beian }),
  methods: {
    toPage(path, query) {
      this.$router.push({ path, query });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer_inner {
  background-color: #4e4f4f;
  // border-top: 4px solid #303133;
  color: #fff;
}

.footer_box {
  // width: 93%;
  margin: 40px auto 30px;
  padding: 0 80px;
}

.footer_left {
  .left_phone_label {
    color: rgba(255, 255, 255, 1);
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 0;
  }

  .left_phone {
    color: #fff;
    font-weight: bold;
    font-size: 34px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .left_name {
    font-size: 18px;
    color: rgba(163, 165, 164, 1);
    line-height: 2;
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 0;
  }

  .left_info {
    font-size: 14px;
    color: rgba(193, 193, 193, 1);
    line-height: 2;
    margin-left: 0px;
    padding-left: 0px;

    &>p {
      margin: 0;
    }
  }
}

.footer_menu {
  padding: 0 50px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 50px;
  margin-right: 50px;

  .menu_item {
    position: relative;

    & h5 {
      font-size: 20px;
      color: #fff;
      margin-bottom: 25px;
    }

    & ul li {
      font-size: 16px;
      color: #b1b1b1;
      margin-bottom: 12px;
      transition: all 0.5s;

      a {
        color: #b1b1b1;
      }
    }
  }
}

.qr_name {
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 16px;
}

.qr_img img {
  width: 180px;
}

.hr_line {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgba(255, 255, 255, 0.1);
  // width: 93%;
  margin: 10px 80px;
  height: 1px;
}

.footer_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 10px;
  // width: 93%;
  padding: 0 80px;

  .divider {
    width: 1px;
    height: 12px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 8px;
  }

  p.copyright,
  .security,
  a.beian {
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    display: block;
  }
}
</style>
